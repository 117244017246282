<template>
  <div class="searchDiv">
    <search-bar v-model:value="pageState.name" :on-search="getList" />
  </div>
  <div class="tableDiv">
    <a-tabs activeKey="0">
      <a-tab-pane tab="教案模板"></a-tab-pane>
      <template #tabBarExtraContent>
        <a-button
          type="primary"
          style="flex: 1"
          class="btn"
          @click="handleCreate()"
        >
          +新建模板
        </a-button>
      </template>
    </a-tabs>
    <div class="templateDiv">
      <div
        class="template"
        v-for="templateItem in pageState.data"
        :key="templateItem.id"
      >
        <div class="editTag" @click="handleEdit(templateItem)">
          <form-outlined /> 编辑
        </div>
        <div class="deleteTag">
          <a-popconfirm
            placement="bottom"
            title="你确定要删除吗"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelete(templateItem)"
          >
            <delete-outlined /> 删除
          </a-popconfirm>
        </div>
        <div class="templatePreview" v-html="templateItem.content"></div>
        <p class="templateTitle"> {{templateItem.name}} </p>
      </div>
    </div>
    <div class="pagination">
      <a-pagination
        v-model:current="pageState.pagination.current"
        v-model:page-size="pageState.pagination.pageSize"
        :total="pageState.pagination.total"
        :show-total="total => `共 ${total} 条`"
        @change="getCurrentPageData"
        show-quick-jumper
      />
    </div>
  </div>
</template>

<script setup>
import { message } from 'ant-design-vue'
import { FormOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { reactive, onBeforeMount } from 'vue'
import { useRouter } from 'vue-router'
import SearchBar from '@/components/common/SearchBar'
import { getTeachPlanTemplateList, deleteTeachPlanTemplate } from '@/services/teach-plan-template'

const router = useRouter()

const pageState = reactive({
  data: [],
  name: '',
  pagination: {
    current: 1,
    total: 1,
    pageSize: 12
  }
})

const handleCreate = () => {
  router.push('/resource/lesson-plan-template/edit/0')
}

const handleEdit = (templateItem) => {
  router.push(`/resource/lesson-plan-template/edit/${templateItem.id}`)
}

const handleDelete = async (templateItem) => {
  await deleteTeachPlanTemplate(templateItem.id)
  message.success('删除成功')
  getList({ page: pageState.pagination.current })
}

const getCurrentPageData = async (page) => {
  pageState.pagination.current_page = page
  await getList({ page: pageState.pagination.current })
}

const getList = async (params) => {
  params = { name: pageState.name, page: 1, per_page: pageState.pagination.pageSize, ...params }
  const { items, pagination } = await getTeachPlanTemplateList(params)
  pageState.data = items
  const { total, pageSize } = pagination
  pageState.pagination.total = total
  pageState.pagination.pageSize = pageSize
  console.log(pagination, items)
  console.log(pageState.data)
  console.log(pageState.data.content)
}

onBeforeMount(async () => {
  await getList()
})

</script>

<style lang="less" scoped>
.searchDiv {
  padding-top: 26px;
  padding-bottom: 22px;
  background-color: #ffffff;
}
.tableDiv {
  background-color: white;
  margin: 10px 20px 0 20px;
  padding: 20px;
  .templateDiv {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    height: 80vh;
    .template {
      position: relative;
      width: 24%;
      height: 30%;
      margin: 0.5%;
      // border: 1px solid #b1b1b1b0;
      box-shadow: 0 0 2px #949494;
      border-radius: 2px;
      background-color: #f4f4f4;
      .editTag {
        display: none;
        text-align: center;
        position: absolute;
        padding: 2px;
        width: 60px;
        height: 25px;
        right: 80px;
        top: 7px;
        border-radius: 2px;
        box-shadow: 0 0 3px #00000060;
        background-color: #fff;
        cursor: pointer;
        z-index: 2;
        .anticon-form {
          color: #00000060;
        }
      }
      .deleteTag {
        display: none;
        text-align: center;
        position: absolute;
        padding: 2px;
        width: 60px;
        height: 25px;
        right: 10px;
        top: 7px;
        border-radius: 2px;
        box-shadow: 0 0 3px #ff6262aa;
        background-color:  #fff;
        color: #ff6262;
        cursor: pointer;
        z-index: 2;
      }
      .templatePreview {
        padding: 0 10px;
        width: 100%;
        height: 82%;
        background-color: #fff;
        overflow: auto;
      }
      .templateTitle {
        margin: 0 auto;
        width: 150px;
        line-height: 4.32vh;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space:nowrap;
      }
    }
    .template:hover {
      .editTag, .deleteTag {
        display: block;
      }
    }
  }
}
.pagination {
  margin-right: 20px;
  text-align: right;
}
</style>
